import request from '@/utils/axios'
import { apiUrl } from '@/utils/global'
// 登录接口
export const accountLogin = (data: object) => request({
  url: '/ua/login',
  method: 'post',
  data
})

// 字典接口
export const listMulti = (params: object) => request({
  url: `${apiUrl.BasicInfoApi}/basicdictinfo/listMulti`,
  method: 'get',
  params
})

// 获取应用模块
export const findSysLinkByUserId = () => request({
  url: `${apiUrl.SystemApi}/syslink/findSysLinkByUserId`,
  method: 'post'
})

// 获取用户信息
export const getUserByHeader = () => request({
  url: `${apiUrl.SystemApi}/sysuser/getUserByHeader`,
  method: 'post'
})

// 获取授权信息
export const getServerInfo = () => request({
  url: `${apiUrl.SystemApi}/server/info`,
  method: 'get'
})