import axios, { AxiosRequestConfig } from 'axios'
import { Message } from 'element-ui'
import { UserModule } from '@/store/modules/user'
const CancelToken = axios.CancelToken
import { cutString } from './common'
const Pending = new Map()
const CancelErrorMsg = 'CancelErrorMsg'
type ServiceConfig = {
  type: 'doLast' | 'doFirst' | undefined;
}

const service = axios.create({
  baseURL: '',
  timeout: 30 * 1000 // 请求超时时间
})

// 记录和显示错误
function errorLog (msg: string): void {
  // 显示提示
  Message({
    message: cutString(msg, 15),
    type: 'error',
    showClose: true,
    duration: 3 * 1000
  })
}

// 创建一个错误
// function errorCreate (msg: string): void {
//   const error = new Error(msg)
//   errorLog(msg)
//   throw error
// }

// 请求拦截器
service.interceptors.request.use(
  config => {
    // 在请求发送之前做一些处理
    const token = UserModule.token
    // 让每个请求携带token-- ['X-Token']为自定义key 请根据实际情况自行修改
    if (token) {
      config.headers.token = token
    }
    return config
  },
  error => {
    // 发送失败
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  res => {
    return res
  },
  error => {
    if (error && error.response) {
      switch (error.response.status) {
        case 400: error.message = '请求错误'; break
        case 401: error.message = '未授权，请登录'; break
        case 403: error.message = '拒绝访问'; break
        case 404: error.message = `请求地址出错: ${error.response.config.url}`; break
        case 408: error.message = '请求超时'; break
        case 500: error.message = '服务器内部错误'; break
        case 501: error.message = '服务未实现'; break
        case 502: error.message = '网关错误'; break
        case 503: error.message = '服务不可用'; break
        case 504: error.message = '网关超时'; break
        case 505: error.message = 'HTTP版本不受支持'; break
        default: break
      }
    }
    if (error.message !== CancelErrorMsg) {
      errorLog(error.message)
    }
    return Promise.reject(error)
    // return error
  }
)

export default async (data: AxiosRequestConfig, config?: ServiceConfig) => {
  let cancelToken
  const { url } = data
  if (config) {
    const { type } = config
    if (type === 'doFirst') {
      // 当只执行首次响应请求时，此时正要执行，则直接返回reject
      if (Pending.has(url)) {
        return Promise.reject(new Error())
      } else {
        Pending.set(url, '')
      }
    } else if (type === 'doLast') {
      // 当只执行尾次的响应时，此时正在执行该请求，做cancel操作
      if (Pending.has(url)) {
        Pending.get(url)(CancelErrorMsg)
      }
      cancelToken = new CancelToken(function executor (c) {
        // executor 函数接收一个 cancel 函数作为参数
        Pending.set(url, c)
      })
    }
  }
  const AxiosResponse = await service({
    ...data,
    cancelToken
  }).catch(() => null)
  // 接口执行完毕后清除pending中保存的状态
  if (Pending.has(url)) {
    Pending.delete(url)
  }
  if (AxiosResponse) {
    // dataAxios 是 axios 返回数据中的 data
    const { data, code, details, msg } = AxiosResponse.data
    // 这个状态码是和后端约定的
    // 根据 code 进行判断
    if (code === undefined) {
      // 如果code返回200 则表示成功
      return data
    } else {
      // 有 code 代表这是一个后端接口 可以进行进一步的判断
      switch (`${code}`) {
        case '200':
          // [ 示例 ] code === 0 代表没有错误
          return data
        case '10001':
          // [ 示例 ] code === 10001 代表认证失败 删除token
          // jsCookie.remove('token')
          // TODO 登出
          UserModule.logout()
          errorLog(`${msg}: ${details}`)
          break
        default:
          // 不是正确的 code
          errorLog(`${msg}: ${details}`)
          break
      }
    }
  }
}
