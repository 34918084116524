import Vue from 'vue'
import Vuex from 'vuex'
import { UserState } from './modules/user'
import { DictState } from './modules/dict'

Vue.use(Vuex)

export interface RootState {
  user: UserState;
  dict: DictState;
}

// Declare empty store first, dynamically register all modules later.
export default new Vuex.Store<RootState>({})
