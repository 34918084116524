import { VueConstructor } from 'vue'
// 导入自己需要的组件
import {
  Row,
  Col,
  Button,
  Form,
  FormItem,
  Input,
  InputNumber,
  Select,
  Option,
  Popover,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Collapse,
  CollapseItem,
  DatePicker,
  Image,
  Loading,
  Tabs,
  TabPane,
  Message, Carousel, CarouselItem
} from 'element-ui'
const components = [
  Row,
  Col,
  Button,
  Form,
  FormItem,
  Input,
  InputNumber,
  Select,
  Option,
  Popover,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Collapse,
  CollapseItem,
  DatePicker,
  Image,
  Tabs,
  TabPane,
  Carousel,
  CarouselItem
]

const element = {
  install (Vue: VueConstructor) {
    components.forEach((item) => {
      Vue.component(item.name, item)
    })
    Vue.use(Loading)
    Vue.prototype.$message = Message
  }
}
export default element
