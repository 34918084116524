import { VuexModule, Module, Action, Mutation, getModule } from 'vuex-module-decorators'
import store from '@/store'
import { getToken, setToken, removeToken, getName, setName, removeName } from '@/utils/cookies'
import { accountLogin } from '@/api/global'
import router, { resetRouter } from '@/router'
import { MessageBox } from 'element-ui'
import { getHostName } from '@/utils/common'

export interface UserState {
  userId: string;
  token: string;
  dynamicRoutesHasAdded: boolean;
}

@Module({ dynamic: true, store, name: 'user' })
class User extends VuexModule implements UserState {
  public userId = getName() || ''
  public token = getToken() || ''
  public dynamicRoutesHasAdded = false

  @Mutation
  SET_USER ({ userId, token }: { userId: string; token: string }) {
    this.userId = userId
    this.token = token
  }

  @Mutation
  SET_ADD_ROUTES (dynamicRoutesHasAdded: boolean) {
    this.dynamicRoutesHasAdded = dynamicRoutesHasAdded
  }

  @Action
  async login (form: { userId: string; password: string }) {
    const data = await accountLogin(form)
    if (data) {
      const { token, userId } = data
      if (!token) {
        return false
      }
      // 登录后
      setName(userId)
      setToken(token)
      this.SET_USER({
        token,
        userId
      })
      return true
    } else {
      return false
    }
  }

  @Action
  async logout () {
    // 清除cookie
    removeName()
    removeToken()
    this.SET_USER({
      token: '',
      userId: ''
    })
    this.SET_ADD_ROUTES(false)
    // 登出后 设置好store值再跳转登录页面
    await router.push({
      name: 'Login'
    })
  }

  @Action
  async confirmLogout () {
    const state = await MessageBox.confirm(
      '确定要注销当前用户吗',
      '注销用户',
      {
        type: 'warning'
      }
    )
    if (state === 'confirm') {
      this.logout()
    }
  }

  @Action
  async test () {
    this.login({
      userId: 'admin',
      password: 'smcaiot'
    })
  }
}

export const UserModule = getModule(User)
