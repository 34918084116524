export const originUrl = process.env.NODE_ENV === 'production'
  ? `${window.location.origin}/`
  : `${process.env.VUE_APP_API}/`

export const apiUrl = {
  SystemApi: '/smcaiot-system', // 系统管理
  MenJinApi: '/smcaiot-entrance', // 门禁系统
  AppApi: '/smcaiot-app', // 移动端服务
  AnalysisApi: '/smcaiot-statistical', // 统计分析
  WuyeApi: '/smcaiot-estatecenter', // 物业中心
  BasicInfoApi: '/smcaiot-basicinfo', // 基础信息
  SearchApi: '/smcaiot-search',
  LogApi: '/smcaiot-loganalysis', // 日志分析
  FileApi: '/smcaiot-filemanagement', // 文件管理
  DataPushTaskApi: '/smcaiot-datapushtask' // 数据推送
}
