import Cookies from 'js-cookie'

// user
const tokenKey = 'token'
export const getToken = (): string | undefined => Cookies.get(tokenKey)
export const setToken = (token: string) => Cookies.set(tokenKey, token)
export const removeToken = () => Cookies.remove(tokenKey)

const nameKey = 'name'
export const getName = (): string | undefined => Cookies.get(nameKey)
export const setName = (name: string) => Cookies.set(nameKey, name)
export const removeName = () => Cookies.remove(nameKey)
