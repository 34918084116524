
import { originUrl } from './global'
// const defaultImageSrc = require('@/assets/images/common/renyuanchuru.jpg')
import defaultImageSrc from '@/assets/images/common/renyuanchuru.jpg'
export function cutString (str: string, threshold: number): string {
  if (str.length > threshold) {
    return str.substring(0, threshold) + '...'
  }
  return str
}
export function getHostName (): string {
  if (!window.location.origin) {
    return `${window.location.protocol}//${window.location.host}`
  } else {
    return window.location.origin
  }
}

export function getImagePath (src: string): string {
  return src ? `${originUrl}${src}` : defaultImageSrc
}
