import Vue from 'vue'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import Router, { RouteConfig } from 'vue-router'
import { UserModule } from '@/store/modules/user'
import { getHostName } from '@/utils/common'

/* Router modules */
import dev from './modules/dev'

Vue.use(Router)

export const constantRoutes: RouteConfig[] = [
  {
    path: '/login',
    name: 'Login',
    meta: {
      title: '登录',
      auth: false
    },
    component: () => import('@/views/Login/index.vue')
  },
  {
    path: '/',
    name: 'enter',
    meta: {
      title: '入口',
      auth: true
    },
    component: () => import('@/views/Enter/index.vue')
  },
  ...(process.env.NODE_ENV === 'development' ? dev : []),
  {
    path: '*',
    redirect: '/'
  }
]
const createRouter = () => new Router({
  // mode: 'history',  // Disabled due to Github Pages doesn't support this, enable this if you need.
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes: constantRoutes
})

const router = createRouter()

export function addRouter () {
  // 添加路由 在matcher上加，如果在router上加容易出现重复
  (router as any).matcher.addRoutes([
    {
      path: '*',
      name: '404',
      component: () => import('@/views/Error/404.vue')
    }
  ])
  UserModule.SET_ADD_ROUTES(true)
}

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
// 将新的router的matcher赋值给旧的，相当于刷新了路由。避免之前为了刷新路由而刷新页面的情况。
export function resetRouter () {
  const newRouter = createRouter();
  (router as any).matcher = (newRouter as any).matcher // reset router
}

router.beforeEach(async (to, from, next) => {
  const { userId, token, dynamicRoutesHasAdded } = UserModule
  const auth = to.matched.some(r => r.meta.auth)
  NProgress.start()
  // 判断当前是否拥有token以及name
  if (userId && token) {
    // 判断是否有更新路由
    // if (dynamicRoutesHasAdded) {
    //   return next()
    // }
    // await addRouter()
    // return next({
    //   ...(to as any),
    //   replace: true
    // })
    return next()
  } else {
    // 无token则判断当前页面是否需要验证
    if (to.matched.length === 0 || auth) {
      next({
        name: 'Login',
        query: {
          redirect: to.fullPath
        }
      })
    } else {
      // 不需验证则正常跳转
      next()
    }
  }
})

router.afterEach(() => {
  NProgress.done()
})

export default router
